import React, { Component, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import logo from './logo.svg';
import support from './assets/images/icons/support.svg'
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom'

//import './style.scss';
//import './style1.scss';
const ViewHome = React.lazy(() => import('./views/Home/Home'));
const ViewMenu = React.lazy(() => import('./views/Menu/Menu'));
const ViewIFO = React.lazy(() => import('./views/IFO/IFO'));
const ViewIFO1 = React.lazy(() => import('./views/IFO1/IFO1'));
const ViewSwap = React.lazy(() => import('./views/Swap/Swap'));
const ViewFarms = React.lazy(() => import('./views/Farms/Farms'));
const ViewPools = React.lazy(() => import('./views/Pools/Pools'));
const LockedPools = React.lazy(() => import('./views/LockedPool/LokedPools'));

const ViewLiquidity = React.lazy(() => import('./views/Liquidity/Liquidity'));
const ViewVoting = React.lazy(() => import('./views/Voting/Voting'));
const ViewVotingdet = React.lazy(() => import('./views/Votingdet/Votingdet'));
const ViewLeaderboard = React.lazy(() => import('./views/Leaderboard/Leaderboard'));
const ViewLeaderboarddet = React.lazy(() => import('./views/Leaderboarddet/Leaderboarddet'));
const ViewLottery = React.lazy(() => import('./views/Lottery/Lottery'));
const ViewMakeproposal = React.lazy(() => import('./views/Makeproposal/Makeproposal'));
const ViewIFOinit = React.lazy(() => import('./views/IFOinit/IFOinit'));
const ViewInfo = React.lazy(() => import('./views/Info/Info'));

const ViewInfoPools = React.lazy(() => import('./views/Info/Pools'));
const ViewInfoPoolDet = React.lazy(() => import('./views/Info/PoolDet'));
const ViewInfoTokens = React.lazy(() => import('./views/Info/Tokens'));
const ViewInfoTokenDet = React.lazy(() => import('./views/Info/TokenDel'));
const ViewProfilesetup = React.lazy(() => import('./views/Profilesetup/Profilesetup'));
const ViewPrediction = React.lazy(() => import('./views/Prediction/Prediction'));
const ViewPredictionhistory = React.lazy(() => import('./views/Predictionhistory/Predictionhistory'));
const ViewCommunityfarms = React.lazy(() => import('./views/Communityfarms/Communityfarms'));
const ViewProfilesetupnew = React.lazy(() => import('./views/Profilesetupnew/Profilesetupnew'));

const Forms = React.lazy(() => import('./views/forms/forms'));
const ViewDenied = React.lazy(() => import('./views/Denied/Denied'));
const ViewLeadership = React.lazy(() => import('./views/Leadership/Leadership'))
const ViewSwapdet = React.lazy(() => import('./views/Swap/Swapdet'));
const ViewDocsPrediction = React.lazy(() => import('./views/Prediction/PredictionDoc'));
const ViewContact = React.lazy(() => import('./views/CMS/Contact'));
const ViewSupport = React.lazy(() => import('./views/CMS/Support'));
const ViewGuide = React.lazy(() => import('./views/CMS/Guide'));
const ViewLearnmore = React.lazy(() => import('./views/CMS/Learnmore'));


const App = (props) => {
  const history = useHistory()
  const deniedStatus = localStorage.getItem("denied")



  useEffect(async () => {
    document.title = "PBEX"
  }, [])


  return (
    <React.Fragment>
      <ToastContainer />
      <Suspense fallback={<div className="loading" />}>
        <Router history={props.history}>
          <Switch>


            <Route path="/" exact render={props => <ViewHome {...props} />} />
            <Route path="/menu" exact render={props => <ViewMenu {...props} />} />
            <Route path="/ifo" exact render={props => <ViewIFO {...props} />} />
            <Route path="/ifo1" exact render={props => <ViewIFO1 {...props} />} />
            <Route path="/swap" exact render={props => <ViewSwap {...props} />} />
            <Route path="/farms" exact render={props => <ViewFarms {...props} />} />
            <Route path="/pools" exact render={props => <ViewPools {...props} />} />
            <Route path="/lockedpools" exact render={props => <LockedPools {...props} />} />

            <Route path="/liquidity" exact render={props => <ViewLiquidity {...props} />} />
            <Route path="/liquidity/:fromTokenSymbol/:toTokenSymbol" exact render={props => <ViewLiquidity {...props} />} />
            <Route path="/voting" exact render={props => <ViewVoting {...props} />} />
            <Route path="/votingdet/:id" exact render={props => <ViewVotingdet {...props} />} />
            <Route path="/leaderboard" exact render={props => <ViewLeadership {...props} />} />
            <Route path="/leaderboarddet" exact render={props => <ViewLeaderboarddet {...props} />} />
            <Route path="/lottery" exact render={props => <ViewLottery {...props} />} />
            <Route path="/makeproposal" exact render={props => <ViewMakeproposal {...props} />} />
            <Route path="/pbx" exact render={props => <ViewIFOinit {...props} />} />
            <Route path="/info" exact render={props => <ViewInfo {...props} />} />



            <Route path="/info/pools" exact render={props => <ViewInfoPools {...props} />} />
            <Route path="/info/pooldet/:name/:symbol/:fromAddr/:toAddr/:pairaddress" exact render={props => <ViewInfoPoolDet {...props} />} />
            <Route path="/info/tokens" exact render={props => <ViewInfoTokens {...props} />} />
            <Route path="/info/tokendet/:name/:symbol/:tokenAddr" exact render={props => <ViewInfoTokenDet {...props} />} />
            <Route path="/info/tokens" exact render={props => <ViewInfoTokens {...props} />} />
            <Route path="/info/tokendet/:name/:symbol" exact render={props => <ViewInfoTokenDet {...props} />} />
            <Route path="/profile" exact render={props => <ViewProfilesetup {...props} />} />
            <Route path="/prediction" exact render={props => <ViewPrediction {...props} />} />
            <Route path="/predictionhistory" exact render={props => <ViewPredictionhistory {...props} />} />
            <Route path="/communityfarms" exact render={props => <ViewCommunityfarms {...props} />} />
            <Route path="/profilenew" exact render={props => <ViewProfilesetupnew {...props} />} />

            <Route path="/communityfarms/approve/:id" render={props => <Forms {...props} />} />
            <Route path="/swapdet" exact render={props => <ViewSwapdet {...props} />} />
            <Route path="/denied" exact render={props => <ViewDenied {...props} />} />
            <Route path="/predictionDoc" exact render={props => <ViewDocsPrediction {...props} />} />
            <Route path="/contact" exact render={props => <ViewContact {...props} />} />
            <Route path="/support" exact render={props => <ViewSupport {...props} />} />
            <Route path="/guide" exact render={props => <ViewGuide {...props} />} />
            <Route path="/learnmore" exact render={props => <ViewLearnmore {...props} />} />
          </Switch>
        </Router>
      </Suspense>
      <a href="https://t.me/peerbluefinanceofficial" target="_blank"><img src={support} className="supportIcon"/></a>
    </React.Fragment>
  );
}
export default App;