import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { userReducer } from "./reducers/userReducer";
import thunk from "redux-thunk"


const middleware = [thunk];

const store = createStore(userReducer,  composeWithDevTools(applyMiddleware(...middleware)))

export default store;
// // import { createStore, applyMiddleware, compose } from 'redux';
// // import thunkMiddleware from 'redux-thunk';
// // import { createLogger } from 'redux-logger';
// // import rootReducer from './rootReducer'

// // const loggerMiddleware = createLogger();
// // const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


// // let middleware = [thunkMiddleware]
// // if (process.env.NODE_ENV !== 'production') {
// //     middleware = [...middleware, loggerMiddleware]
// // }

// // const store = createStore(
// //     rootReducer,
// //     composeEnhancer(
// //         applyMiddleware(...middleware))
// // );

// // export default store;
