export const proposal = {
    PROPOSAL_PAGE_ADD_REQ: "PROPOSAL_PAGE_ADD_REQ",
    PROPOSAL_PAGE_ADD_SUCCESS: "PROPOSAL_PAGE_ADD_SUCCESS",
    PROPOSAL_PAGE_ADD_FAILURE: "PROPOSAL_PAGE_ADD_SUCCESS",
  
    PROPOSAL_PAGE_GET_REQ: "PROPOSAL_PAGE_GET_REQ",
    PROPOSAL_PAGE_GET_SUCCESS: "PROPOSAL_PAGE_GET_SUCCESS",
    PROPOSAL_PAGE_GET_FAILURE: "PROPOSAL_PAGE_GET_FAILURE",
  
    ///proposal single page
  
    PROPOSAL_PAGE_GET_SINGLE_REQ: "PROPOSAL_PAGE_GET_SINGLE_REQ",
    PROPOSAL_PAGE_GET_SINGLE_SUCCESS: "PROPOSAL_PAGE_GET_SINGLE_SUCCESS",
    VOTE_PAGE_DETAILS_DATA:"VOTE_PAGE_DETAILS_DATA",
    VOTE_COUNT_SUCCESS:"VOTE_COUNT_SUCCESS",
    PROPOSAL_PAGE_PROPOSAL_STATUS:"PROPOSAL_PAGE_PROPOSAL_STATUS",
    PROPOSAL_PAGE_GET_SINGLE_FAILURE: "PROPOSAL_PAGE_GET_SINGLE_FAILURE",
    VOTE_TIME_SUCCESS:"VOTE_TIME_SUCCESS",
  
    // create vote
  
    CREATE_VOTE_PAGE_REQ: "CREATE_VOTE_PAGE_REQ",
    CREATE_VOTE_PAGE_SUCCESS: "CREATE_VOTE_PAGE_SUCCESS",
    CREATE_VOTE_PAGE_FAILURE: "CREATE_VOTE_PAGE_FAILURE",
    CREATE_VOTE_PAGE_COUNT: "CREATE_VOTE_PAGE_COUNT",
  
    //get proposal
  
    PROPOSAL_PAGE_REQ:"PROPOSAL_PAGE_REQ",
    PROPOSAL_PAGE_SUCCESS:"PROPOSAL_PAGE_SUCCESS",
    PROPOSAL_PAGE_FAILURE:"PROPOSAL_PAGE_FAILURE",


    //leaderboard

    LEADERBOARD_PAGE_SWAP_REQ:"LEADERBOARD_PAGE_SWAP_REQ",
    LEADERBOARD_PAGE_SWAP_SUCCESS:"LEADERBOARD_PAGE_SWAP_SUCCESS",
    LEADERBOARD_PAGE_SWAP_FAILURE:"LEADERBOARD_PAGE_SWAP_FAILURE",

    //pool
    LEADERBOARD_PAGE_POOL_REQ:"LEADERBOARD_PAGE_POOL_REQ",
    LEADERBOARD_PAGE_POOL_SUCCESS:"LEADERBOARD_PAGE_POOL_SUCCESS",
    LEADERBOARD_PAGE_POOL_FAILURE:"LEADERBOARD_PAGE_POOL_FAILURE",

    //FARMS

    LEADERBOARD_PAGE_FARMS_REQ:"LEADERBOARD_PAGE_FARMS_REQ",
    LEADERBOARD_PAGE_FARMS_SUCCESS:"LEADERBOARD_PAGE_FARMS_SUCCESS",
    LEADERBOARD_PAGE_FARMS_FAILURE:"LEADERBOARD_PAGE_FARMS_FAILURE",

    //LOTTERY

    LEADERBOARD_PAGE_LOTTERY_REQ:"LEADERBOARD_PAGE_LOTTERY_REQ",
    LEADERBOARD_PAGE_LOTTERY_SUCCESS:"LEADERBOARD_PAGE_LOTTERY_SUCCESS",
    LEADERBOARD_PAGE_LOTTERY_FAILURE:"LEADERBOARD_PAGE_LOTTERY_FAILURE",
    
    //Leaderboard post

    LEADERBOARD_POST_REQ:"LEADERBOARD_POST_REQ",
    LEADERBOARD_POST_SUCCESS:"LEADERBOARD_POST_SUCCESS",
    LEADERBOARD_POST_FAILURE:"LEADERBOARD_POST_FAILURE",

    //get pool page

    POOL_PAGE_GET_REQ:"POOL_PAGE_GET_REQ",
    POOL_PAGE_GET_SUCCESS:"POOL_PAGE_GET_SUCCESS",
    POOL_PAGE_GET_FAILURE:"POOL_PAGE_GET_FAILURE",

    //farms

    FARMS_PAGE_REQ:"FARMS_PAGE_REQ",
    FARMS_PAGE_SUCCESS:"FARMS_PAGE_SUCCESS",
    FARMS_PAGE_FAILURE:"FARMS_PAGE_FAILURE",

    //LOTTERY
    LOTTERY_PAGE_REQ:"LOTTERY_PAGE_REQ",
    LOTTERY_PAGE_SUCCESS:"LOTTERY_PAGE_SUCCESS",
    LOTTERY_PAGE_FAILURE:"LOTTERY_PAGE_FAILURE",
    LIQ_DATA:"LIQ_DATA",

    PBEX_POOL_PAGE_REQ:"PBEX_POOL_PAGE_REQ",
    PBEX_POOL_PAGE_SUCCESS:"PBEX_POOL_PAGE_SUCCESS",
    PBEX_POOL_PAGE_FAILURE:"PBEX_POOL_PAGE_FAILURE",

  
  };
  
  