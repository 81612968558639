import { proposal } from "../constants/userConstant";
import { WALLET_DATA, SETTINGS_DATA,LIQ_DATA,COMMON_WALLET_DATA} from "../constant.js";

const initialState = {
  loader: false,
  proposal_add_pages: [],
  proposal_add_pagef: [],
  proposal_get_pages: [],
  proposal_get_pagef: [],
  proposal_get_single_datas: [],
  proposal_get_single_dataf: [],
  create__datas: [],
  create__dataf: [],
  create__count: [],
  GET__DATAS: [],
  GET__DATAF: [],
  vote_details: [],
  proposal_status: [],
  enableLoader: [],
  Swap__DATAS: [],
  pool__DATAS: [],
  farms__DATAS: [],
  lottery__DATAS: [],
  get__tokens: [],
  POOL__datas: [],
  Lottery__datas: [],
  time_details: [],
  pbex__datas:[],
  Liq_data:false
};

export function userReducer(state = initialState, action) {
  if (!action || (action && !action.type)) return state;
  switch (action.type) {
    case proposal.PROPOSAL_PAGE_ADD_REQ:
      return {
        ...state,
        loader: true,
      };
    case proposal.PROPOSAL_PAGE_ADD_SUCCESS:
      return {
        ...state,
        proposal_add_pages: action.data,
        loader: false,
      };
    case proposal.PROPOSAL_PAGE_ADD_FAILURE:
      return {
        ...state,
        proposal_add_pagef: false,
        loader: false,
      };

    case proposal.PROPOSAL_PAGE_GET_REQ:
      return {
        ...state,
        loader: true,
      };
    case proposal.PROPOSAL_PAGE_GET_SUCCESS:
      return {
        ...state,
        proposal_get_pages: action.data,
        loader: false,
      };
    case proposal.PROPOSAL_PAGE_GET_FAILURE:
      return {
        ...state,
        proposal_get_pagef: false,
        loader: false,
      };

    case proposal.PROPOSAL_PAGE_GET_SINGLE_REQ:
      return {
        ...state,
        loader: true,
      };
    case proposal.PROPOSAL_PAGE_GET_SINGLE_SUCCESS:
      return {
        ...state,
        proposal_get_single_datas: action.data,
        loader: false,
      };

    case proposal.VOTE_PAGE_DETAILS_DATA:
      return {
        ...state,
        vote_details: action.data,
        loader: false,
      };
    //time
    case proposal.VOTE_TIME_SUCCESS:
      return {
        ...state,
        time_details: action.data,
        loader: false,
      };
    case proposal.VOTE_COUNT_SUCCESS:
      return {
        ...state,
        create__count: action.data,
        loader: false,
      };

    case proposal.PROPOSAL_PAGE_PROPOSAL_STATUS:
      return {
        ...state,
        proposal_status: action.data,
        loader: false,
      };

    case proposal.PROPOSAL_PAGE_GET_SINGLE_FAILURE:
      return {
        ...state,
        proposal_get_single_dataf: false,
        loader: false,
      };

    case proposal.CREATE_VOTE_PAGE_REQ:
      return {
        ...state,
        loader: true,
      };
    case proposal.CREATE_VOTE_PAGE_SUCCESS:
      return {
        ...state,
        create__datas: action.data,
        loader: false,
      };
    case proposal.CREATE_VOTE_PAGE_COUNT:
      return {
        ...state,
        create__count: action.data,
        loader: false,
      };
    case proposal.CREATE_VOTE_PAGE_FAILURE:
      return {
        ...state,
        create__dataf: false,
        loader: false,
      };

    case proposal.LEADERBOARD_PAGE_SWAP_REQ:
      return {
        ...state,
        create__datas: action.data,
        loader: false,
      };
    case proposal.LEADERBOARD_PAGE_SWAP_SUCCESS:
      return {
        ...state,
        Swap__DATAS: action.data,
        loader: false,
      };
    case proposal.LEADERBOARD_PAGE_SWAP_FAILURE:
      return {
        ...state,
        Swap__DATAF: false,
        loader: false,
      };

    case proposal.LEADERBOARD_POST_REQ:
      return {
        ...state,
        get__tokens: action.data,
        loader: false,
      };
    case proposal.LEADERBOARD_POST_SUCCESS:
      return {
        ...state,
        get__tokens: action.data,
        loader: false,
      };
    case proposal.LEADERBOARD_POST_FAILURE:
      return {
        ...state,
        get__tokenF: false,
        loader: false,
      };

    case proposal.LEADERBOARD_PAGE_POOL_REQ:
      return {
        ...state,
        create__datas: action.data,
        loader: false,
      };
    case proposal.LEADERBOARD_PAGE_POOL_SUCCESS:
      return {
        ...state,
        pool__DATAS: action.data,
        loader: false,
      };
    case proposal.LEADERBOARD_PAGE_POOL_FAILURE:
      return {
        ...state,
        pool__DATAf: false,
        loader: false,
      };
    //GET POOLLIST

    case proposal.POOL_PAGE_GET_REQ:
      return {
        ...state,
        POOL__datas: action.data,
        loader: false,
      };
    case proposal.POOL_PAGE_GET_SUCCESS:
      return {
        ...state,
        POOL__datas: action.data,
        loader: false,
      };
    case proposal.POOL_PAGE_GET_FAILURE:
      return {
        ...state,
        POOL__dataF: false,
        loader: false,
      };

      case proposal.PBEX_POOL_PAGE_REQ:
        return {
          ...state,
          POOL__datas: action.data,
          loader: false,
        };
      case proposal.PBEX_POOL_PAGE_SUCCESS:
        return {
          ...state,
          pbex__datas: action.data,
          loader: false,
        };
      case proposal.PBEX_POOL_PAGE_FAILURE:
        return {
          ...state,
          pbex__dataF: false,
          loader: false,
        };
    //LOTTERY PAGE

    case proposal.LOTTERY_PAGE_REQ:
      return {
        ...state,
        Lottery__datas: action.data,
        loader: false,
      };
    case proposal.LOTTERY_PAGE_SUCCESS:
      return {
        ...state,
        Lottery__datas: action.data,
        loader: false,
      };
    case proposal.LOTTERY_PAGE_FAILURE:
      return {
        ...state,
        Lottery__dataf: false,
        loader: false,
      };

    case proposal.LEADERBOARD_PAGE_FARMS_REQ:
      return {
        ...state,
        create__datas: action.data,
        loader: false,
      };
    case proposal.LEADERBOARD_PAGE_FARMS_SUCCESS:
      return {
        ...state,
        farms__DATAS: action.data,
        loader: false,
      };
    case proposal.LEADERBOARD_PAGE_FARMS_FAILURE:
      return {
        ...state,
        farms__DATAf: false,
        loader: false,
      };

    case proposal.LEADERBOARD_PAGE_LOTTERY_REQ:
      return {
        ...state,
        create__datas: action.data,
        loader: false,
      };
    case proposal.LEADERBOARD_PAGE_LOTTERY_SUCCESS:
      return {
        ...state,
        lottery__DATAS: action.data,
        loader: false,
      };
    case proposal.LEADERBOARD_PAGE_LOTTERY_FAILURE:
      return {
        ...state,
        lottery__DATAf: false,
        loader: false,
      };

    case proposal.PROPOSAL_PAGE_REQ:
      return {
        ...state,
        create__datas: action.data,
        loader: false,
      };
    case proposal.PROPOSAL_PAGE_SUCCESS:
      return {
        ...state,
        GET__DATAS: action.data,
        loader: false,
      };
    case proposal.PROPOSAL_PAGE_FAILURE:
      return {
        ...state,
        GET__DATAF: false,
        loader: false,
      };
    case WALLET_DATA:
      return {
        ...state,
        walletData: action.payload,
      };

      case COMMON_WALLET_DATA:
        return {
          ...state,
          walletData: action.payload,
        };

    case SETTINGS_DATA:
      return {
        ...state,
        settingsData: action.payload,
      };

      case LIQ_DATA: {
        return {
            ...state,
            Liq_data: action.payload
        }
    }
      
    default:
      return state;
  }
}
